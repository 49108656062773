import axios from "axios";

let smartPdvUrl = "https://apistore.smartpdvstore.com"

async function getCatalog(brandCode, catalogCode, brand_url) {
    try {
        const response = await axios.get(`${smartPdvUrl}/store/catalogos/${brandCode}/${catalogCode}`, {
        headers: {
            Marca: brand_url
        }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

async function getBrand(brand_url) {
    try {
        const response = await axios.get(`${smartPdvUrl}/admin/marcas`, {
        headers: {
            Marca: brand_url
        }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export { 
    getCatalog,
    getBrand
};