<template>
    <div class="btnWhatsppRightFixed" @click="openWhatsapp">
        <img src="@/assets/icons/whatsapp2.svg" alt="" >
        <p>Fale conosco</p>
    </div>
</template>

<script>
export default {
    name: 'whatsappFloat',
    props: {
        brand: {
            type: Object,
            required: true
        }
    },
    mounted() {
        console.log('brand', this.brand);
    },
    methods: {
        openWhatsapp() {
            window.open(`https://wa.me/55${this.brand.whatsapp}?text=${this.brand.mensagemWhatsapp}`, '_blank');
        }
    }
}
</script>

<style scoped>
.btnWhatsppRightFixed {
    position: fixed;
    bottom: 0;
    right: 20px;
    z-index: 999;
    cursor: pointer;
    background-color: #128c7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 30px 0 30px;
}

.btnWhatsppRightFixed:hover {
    background-color: #14aa99;
}

.btnWhatsppRightFixed img {
    width: 25px;
    margin-right: 10px;
    margin-bottom: 4px;
}

.btnWhatsppRightFixed p {
    float: right;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 5px 0;

}
</style>