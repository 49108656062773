<template>
    <v-container>
        <v-row>
            <v-col style="padding: 0;margin: 0;">
               <div class="card">
                    <div class="image">
                        <img :src="defaultImage.nome" alt="" @click="showImages(defaultImage, product.codigo)">
                        <!-- <vue-picture-swipe :ref="product.codigo" :items="imagensSlide"></vue-picture-swipe> -->
                    </div>
                    <div class="body" v-if="product.cores != ''">
                        <h2>{{product.nome}}</h2>
                        <p class="ref">Ref.: {{ product.referencia }}</p>
                        <br>
                        <p class="price">{{ product.produtoFiliais[0].valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                        <p class="composition"> {{ product.composicao }}</p>
                        <p class="description">{{ product.descricao }}</p>
                        <br>
                        <p class="colors">Cores e estampas</p>
                        <v-container class="tamanhos">
                            
                            <v-row v-for="(c, index) in product.cores" :key="index" @click="changeDefaultImage(c)" class="cor-item">
                                <v-col cols="12">
                                    <v-row>
                                <v-col cols="1" style="font-size: 13pt;">
                                    Cor
                                </v-col>
                                <v-col cols="3">

                                </v-col>
                                <v-col style="font-size: 13pt;">
                                    <v-row>
                                        <v-col cols="2" v-for="(f, index) in availableSizes" :key="index" style="text-align: center;">
                                            {{ f }}
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <div class="estampa" v-if="c.estampaImagem != null">
                                        <img :src="generateImageUrl(c.estampaImagem)" alt="">
                                    </div>
                                    <div class="cor" :style="`background-color: ${c.hex}`" v-if="c.estampaImagem == null"></div>
                                </v-col>
                                <v-col cols="3">
                                    <strong>{{ c.nomeEstampa != null ? c.nomeEstampa : c.nome }} - {{ ('000' + c.codWear).substr(-3) }}</strong>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col cols="2" v-for="(f, index) in product.produtoFiliais.filter(x => x.cor == c.codigo)"  :key="index">
                                            <div class="info-stock">
                                                E.: {{ f.estoque }}
                                            </div>
                                            <input 
                                                class="input-qnt"
                                                :ref="`${c.codWear}-${index}`"
                                                v-model="f.estoquePedido"
                                                @keyup="verifyStock(f, `${c.codWear}-${index}`)"
                                                v-if="f.estoque > 0"
                                            >

                                            <input 
                                                class="input-disabled"
                                                v-else
                                                value="Ind."
                                                disabled
                                            >
                                        </v-col>
                                    </v-row>
                                    <!-- <div class="form-qnt" v-for="(f, index) in product.produtoFiliais.filter(x => x.corWear == c.codWear)"  :key="index">
                                        <span>Est.: {{  f.estoque }}</span>
                                        <input 
                                            class="input-qnt"
                                            :ref="`${c.codWear}-${index}`"
                                            v-model="f.estoquePedido"
                                            @keyup="verifyStock(f, `${c.codWear}-${index}`)"
                                            v-if="f.estoque > 0"
                                        >

                                        <input 
                                            class="input-disabled"
                                            v-else
                                            value="Ind."
                                            disabled
                                        >
                                        
                                    </div> -->
                                      
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" style="text-align: center;">
                                    <button class="btn-add-cart" @click="addProductToCart">
                                        Adicionar ao carrinho
                                    </button>
                                </v-col>
                                <v-col>
                                    

                                    <v-dialog
                                        :value="alert"
                                        persistent
                                        width="450"
                                        style="box-shadow: none !important;"
                                    >
                                    <v-alert
                                        dense
                                        type="success"
                                        transition="fade-transition"
                                        v-if="alertData.totalProducts > 1"
                                        style="box-shadow: none !important;margin: 0 !important;"
                                    >
                                        Todos seus {{ alertData.totalProducts }} itens foram adicionados ao carrinho.
                                    </v-alert>

                                    <v-alert
                                        dense
                                        type="success"
                                        transition="fade-transition"
                                        style="box-shadow: none !important;margin: 0 !important;"
                                        v-if="alertData.totalProducts == 1"
                                    >
                                        Seu item foi adicionado ao carrinho.
                                    </v-alert>

                                    </v-dialog>



                                    <v-dialog
                                        :value="alertError"
                                        persistent
                                        width="450"
                                        style="box-shadow: none !important;"
                                    >
                                    <v-alert
                                        dense
                                        type="error"
                                        transition="fade-transition"
                                        style="box-shadow: none !important;margin: 0 !important;"
                                    >
                                        Não foi possível adicionar ao carrinho, verifique a quantidade de produtos.
                                    </v-alert>


                                    </v-dialog>
                                </v-col>
                                
                            </v-row>
                        </v-container>   
                    </div>
                    <div v-else>
                        <h2>{{product.nome}}</h2>
                        <p class="ref">Ref.: {{ product.referencia }}</p>
                        <br>
                        <p class="unavailable">(Indisponível)</p>
                        
                    </div>
               </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
/* eslint-disable */
import generateImageUrlProduct from '@/utils/generateImageUrlProduct';
import generateImageUrl from '@/utils/generateImageUrl';
import VuePictureSwipe from 'vue-picture-swipe';

function isMobile(){
    return window.innerWidth < 768
}

export default {
    name: 'cardProduct',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    components: {
        VuePictureSwipe
    },  
    data(){
        return {
            alert: false,
            alertData:{
              totalProducts: 0  
            },

            alertError: false,
            defaultImage: {},
            actualColor: {},
            availableSizes: [],
            imagensSlide: [
                // {src: defaultImage, thumbnail: defaultImage,w: 750,h: 1000, title: 'Will be used for caption'}
            ],
            approvedToProceed: false

        }
    },
    async mounted(){
        let imageToCreateUrl = ''
        if(this.product.cores.length > 0 && this.product.cores[0].imagens){
            imageToCreateUrl = this.product.cores.filter(x => x.imagens)[0] != undefined ? this.product.cores.filter(x => x.imagens)[0].imagens.find(x => x.ordem == 1) : {nome: 'https://charmosatravessia.smartpdvstore.com/sem-imagem.jpg'}
        }else{
            imageToCreateUrl = (this.product.corPadrao && this.product.corPadrao.imagens) ? this.product.corPadrao.imagens.filter(x => x.ordem == 1)[0] : {nome: 'https://charmosatravessia.smartpdvstore.com/sem-imagem.jpg'}
        }

        this.defaultImage = await generateImageUrlProduct(imageToCreateUrl, this.$store.state.userData)
        for (const e of this.product.cores) {
            let produtoFiliais = this.product.produtoFiliais.filter(x => x.cor == e.codigo)
            for (const f of produtoFiliais) {
                this.availableSizes.push(this.product.grade.tamanhos.find(x => x.posicao == f.tamanhoPosicao).descricao)
            }
        }
        this.availableSizes = this.availableSizes.filter(function(item, pos) {
                                return this.availableSizes.indexOf(item) == pos;
                            }, this)
        // this.showImages(this.defaultImage, this.product.codigo)
    },
    methods: {
        generateImageUrl(image) {
            return generateImageUrl(image, this.$store.state.userData)
        },
        changeDefaultImage(cor){
            console.log(cor.imagens.find(x => x.ordem == 1))
            if(!cor.imagens) return
            this.defaultImage = generateImageUrlProduct(cor.imagens.find(x => x.ordem == 1), this.$store.state.userData)
        },
        searchImageToCart(cor){
            return cor.imagens ? generateImageUrlProduct(cor.imagens.find(x => x.ordem == 1), this.$store.state.userData) : 'https://charmosatravessia.smartpdvstore.com/sem-imagem.jpg'
        },
        async findImagesList(defaultImage){
            for (const e of this.product.cores) {
                if(e.imagens != null){
                    for (const f of e.imagens){
                        if(f.codigo == defaultImage.codigo){
                            return e.imagens
                        }
                    }
                }else{
                    return []
                }
            }
        },
        async showImages(defaultImage, codigo){
            //{src: defaultImage, thumbnail: defaultImage,w: 750,h: 1000, title: 'Will be used for caption'}
            let images = await this.findImagesList(defaultImage)
            for (const e of images) {
                this.imagensSlide.push({src: (generateImageUrlProduct(e, this.$store.state.userData)).nome, thumbnail: (generateImageUrlProduct(e, this.$store.state.userData)).nome, w: 750, h: 1000, title: 'Will be used for caption'})
            }
            setTimeout(() => {
                console.log(this.$refs[codigo])
            }, 1000);
        },
        async openProductDetails(p){
            this.$router.push({path: `/${this.$route.params.catalogCode}/${p.codigo}` })
        },
        async verifyIfAllIsApprovedToProceed(){
            let sum = 0
            for (const e of this.product.produtoFiliais) {
                let estoquePedido = e.estoquePedido == undefined ? 0 : parseInt(e.estoquePedido)
                estoquePedido = isNaN(estoquePedido) ? 0 : estoquePedido
                sum += estoquePedido
                if(estoquePedido > e.estoque){
                    console.log(e)
                    this.approvedToProceed = false
                    return
                }
            }

            if(sum > 0){
                this.approvedToProceed = true
            }else{
                this.approvedToProceed = false
            }
        },
        async verifyStock(f, ref){
            if(f.estoquePedido > f.estoque){
                this.$refs[ref][0].classList.add('error-input')
                this.$refs[ref][0].classList.remove('success-input')
                this.verifyIfAllIsApprovedToProceed()
            }else{
                this.$refs[ref][0].classList.add('success-input')
                this.$refs[ref][0].classList.remove('error-input')
                this.verifyIfAllIsApprovedToProceed()

            }
        },
        async addProductToCart(){
            if(!this.approvedToProceed){
                this.alertError = true

                setTimeout(() => {
                    this.alertError = false
                }, 2000);
                return
            }
            let products = this.product.produtoFiliais.filter(x => x.estoquePedido)
            this.alertData.totalProducts = products.reduce((a, b) => a + parseInt(b.estoquePedido), 0)
            for (let e of products) {
                let cores = {}
                if(this.product.cores.length > 0){
                    cores = this.product.cores.find(x => x.codigo == e.cor)
                }else{
                    cores = this.product.corPadrao
                }

                let corData = cores
                let imagem = ''

                if(corData.imagens != null){
                    imagem = corData.imagens.find(x => x.ordem == 1)
                }else{
                    imagem = generateImageUrlProduct(this.product.corPadrao.imagens.find(x => x.ordem == 1), this.$store.state.userData)
                }
                e.corData = corData
                e.nome = this.product.nome
                e.referencia = this.product.referencia
                e.relevancia = this.product.relevancia
                e.status = this.product.status
                e.descricao = this.product.descricao
                e.codigo = this.product.codigo
                e.estoquePedido = parseInt(e.estoquePedido)
                e.imagem =  imagem
                e.tamanho = this.product.grade.tamanhos.find(x => x.posicao == e.tamanhoPosicao).descricao
                localStorage.cart = JSON.stringify([...JSON.parse(localStorage.cart), e])
                this.$emit('updateCart')
                this.alert = true


                //remover classes de erro e sucesso de todos os inputs
                let inputs = document.querySelectorAll('.input-qnt')
                for (const e of inputs) {
                    e.classList.remove('error-input')
                    e.classList.remove('success-input')
                }
                
                e.estoquePedido = ''

                setTimeout(() => {
                    this.alert = false
                }, 2000);
            }
        }
    }
}
</script> 

<style scoped>
    .card {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        margin: 10px;
        aspect-ratio: .47;
        background-color: var(--mantine-color-white);
        box-shadow: 0 1px 4px #0006;
        border-radius: 3px;
    }

    .card .image {
        height: 75%;
    }

    .card .image img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    h2 {
        font-size: 1.2rem;
        margin: 0;
        padding: 10px;
        text-align: center;
    }
    .ref{
        color: #868e96;
        font-weight: bold;
        text-align: center;
    }
    .price{
        font-weight: 900;
        text-align: center;
        font-size: 1.4rem;
    }

    .colors{
        color: #868e96;
        font-weight: bold;
        text-align: center;
    }

    .estampa{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .cor {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #00000049;
    }

    .tamanhos{
        /* margin: 10px; */
        font-size: .8rem;
    }

    .input-qnt{
        width: 37px;
        height: 38px;
        border-radius: 5px;
        border: 1px solid #00000049;
        text-align: center;
        margin-right: 5px;
    }

    /* REMOVE INPUT FOCUS BORDER */
    .input-qnt:focus{
        outline: none;
    }

    .input-disabled{
        width: 37px;
        height: 38px;
        border-radius: 5px;
        border: 1px solid #00000049;
        text-align: center;
        margin-right: 5px;
        background-color: #dadada;
    }

    .error-input{
        border: 2pt solid red;
        color: red;
    }

    .success-input{
        border: 1px solid green;
        color: green;
    }

    .btn-add-cart{
        width: 50%;
        padding: 10px;
        color: #ff4e54;
        border: 2px solid #ff4e54;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
    }

    .composition{
        font-size: 1.2rem;
        text-align: center;
    }

    .description{
        font-size: .8rem;
        margin-left: 15px;
        padding-top: 15px;
        text-align: justify
    }


    .form-qnt{
        display: inline
    }

    .info-stock{
        position: absolute;
        background-color: #ffbebe;
        margin-top: -23px;
        margin-left: 5px;
        box-shadow: 1px 2px 3px #69696975;
        border-radius: 3px;
        padding: 1px;
        font-size: .75rem;
    }

    .cor-item{
        cursor: pointer;
        border-bottom: 1px solid #00000049;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .unavailable{
        font-size: 1.3rem;
        text-align: left;
        color: #868e96;
        margin-left: 20px;
        font-style: italic;
    }
</style>