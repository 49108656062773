document.addEventListener('DOMContentLoaded', () => {
  try {
    // Verificar suporte a Blob e URL.createObjectURL
    if (!window.Blob || !window.URL || !window.URL.createObjectURL) {
      console.log('Seu navegador não suporta Blob ou URL.createObjectURL');
      return;
    }

    // Verificar disponibilidade do localStorage
    let userData;
    try {
      userData = JSON.parse(localStorage.userData);
      console.log('Dados do usuário carregados:', userData);
    } catch (error) {
      console.log('Erro ao acessar os dados do usuário no localStorage');
      console.log('Erro ao acessar os dados do usuário no localStorage:', error);
      return;
    }

    // Criar novo manifest
    let manifest = {
      id: "com.pedistore." + userData.id,
      name: `Pedido Fácil - ` + userData.full_name,
      short_name: "Pedido Fácil - " + userData.full_name,
      start_url: "https://pedistore.com.br/" + userData.catalogue_code,
      display: "standalone",
      theme_color: "#0464ff",
      background_color: "#0464ff",
      icons: [
        {
          src: "https://pedistore.com.br/img/icons/android-chrome-192x192.png",
          sizes: "192x192",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/android-chrome-512x512.png",
          sizes: "512x512",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/android-chrome-maskable-192x192.png",
          sizes: "192x192",
          type: "image/png",
          purpose: "maskable"
        },
        {
          src: "https://pedistore.com.br/img/icons/android-chrome-maskable-512x512.png",
          sizes: "512x512",
          type: "image/png",
          purpose: "maskable"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon-60x60.png",
          sizes: "60x60",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon-76x76.png",
          sizes: "76x76",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon-120x120.png",
          sizes: "120x120",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon-152x152.png",
          sizes: "152x152",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon-180x180.png",
          sizes: "180x180",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/apple-touch-icon.png",
          sizes: "180x180",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/favicon-16x16.png",
          sizes: "16x16",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/favicon-32x32.png",
          sizes: "32x32",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/msapplication-icon-144x144.png",
          sizes: "144x144",
          type: "image/png"
        },
        {
          src: "https://pedistore.com.br/img/icons/mstile-150x150.png",
          sizes: "150x150",
          type: "image/png"
        }
      ],
    };

    // Criar Blob e URL
    let stringManifest = JSON.stringify(manifest, null, 2);
    let blob = new Blob([stringManifest], { type: 'application/json' });
    let url = URL.createObjectURL(blob);
    console.log('URL do Blob criada:', url);

    // altera href do link manifest ou adiciona novo link
    let oldManifest = document.querySelector('link[rel="manifest"]');
    if (oldManifest) {
      oldManifest.href = url;
      console.log('Link do manifesto atualizado:', oldManifest.href);
    } else {
      let link = document.createElement('link');
      link.rel = 'manifest';
      link.href = url;
      document.head.appendChild(link);
      console.log('Link do manifesto adicionado:', link.href);
    }

    // Adicionar meta tags específicas para iOS
    let metaTags = [
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
      { name: 'apple-mobile-web-app-title', content: `Pedido Fácil - ${userData.full_name}` },
      { name: 'application-name', content: `Pedido Fácil - ${userData.full_name}` }
    ];

    metaTags.forEach(tag => {
      let meta = document.querySelector(`meta[name="${tag.name}"]`);
      if (meta) {
        meta.content = tag.content;
        console.log(`Meta tag atualizada: ${tag.name} = ${tag.content}`);
      } else {
        meta = document.createElement('meta');
        meta.name = tag.name;
        meta.content = tag.content;
        document.head.appendChild(meta);
        console.log(`Meta tag adicionada: ${tag.name} = ${tag.content}`);
      }
    });

    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log('Manifesto carregado:', data);
        console.log('Manifesto carregado com sucesso');
      })
      .catch(error => {
        console.error('Erro ao carregar o manifesto:', error);
        console.log('Erro ao carregar o manifesto');
      });

    console.log('Manifest mudado');
  } catch (error) {
    console.log('Erro ao mudar o manifest');
    console.log('Erro ao mudar o manifest:', error);
  }
});

// Funções de callback do service worker
const serviceWorkerCallbacks = {
  registered() {
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated() {
    console.log('New content is available; please refresh.');
    window.location.reload();
    // Forçar a atualização do service worker
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ action: 'skipWaiting' });
    }
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  }
};

// Registrar o service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Service worker registered with scope:', registration.scope);
      serviceWorkerCallbacks.registered();

      // Verificar se há uma atualização disponível
      if (registration.waiting) {
        serviceWorkerCallbacks.updated();
      }

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              serviceWorkerCallbacks.updated();
            } else {
              serviceWorkerCallbacks.cached();
            }
          }
        };
      };
    })
    .catch(error => {
      serviceWorkerCallbacks.error(error);
    });

  // Listener para mensagens do service worker
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data.action === 'skipWaiting') {
      window.location.reload();
    }
  });
}

// Função para limpar o cache do navegador
function clearCache() {
  if ('caches' in window) {
    caches.keys().then(names => {
      for (let name of names) {
        caches.delete(name);
      }
    });
  }
}

// Limpar o cache do navegador
clearCache();