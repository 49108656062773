import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import config from './config'
const api = axios.create({
    baseURL: config.baseURL
})

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData:{
      "id": 0,
      "full_name": "",
      "email": "",
      "password": "",
      "client_code": "",
      "brand_code": "",
      "brand_url": "",
      "catalogue_code": "",
      "role": "",
    },
    cartTotal: 0,
  },
  mutations: {
    setUserData(state, userData){
      state.userData = userData
    },
    updateCartTotal(state, total){
      state.cartTotal = total
    }
  },
  actions: {
    async getStore(state, catalogue_code){
        try {
          let response = await api.get(`/users/${catalogue_code}`)
          if(!response.data) return
          state.commit('setUserData', response.data)
          localStorage.setItem('userData', JSON.stringify(response.data))
          return JSON.parse(localStorage.getItem('userData'))

        } catch (error) {
            console.log(error)
        }
    },
  },
  getters: {
    getUserData(state){
      return state.userData
    },
    isMobile(){
      return window.innerWidth < 768
    },
    async getConfig(){
      return {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    },
    
  }
});
