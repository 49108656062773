<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      title: {},
    }
  },
  async created(){
    let catalogCode = this.$route.params.catalogCode
    let response = await this.$store.dispatch('getStore', catalogCode)
    this.userData = response
    localStorage.setItem('userData', JSON.stringify(response))
    this.$store.commit('setUserData', response)
    let dados = this.$store.state.userData
    this.title = dados.full_name
    console.log('dados', dados)
    console.log('title', this.title)
    if(!localStorage.cart){
      localStorage.setItem('cart', JSON.stringify([]))
    }


    if(this.userData.need_password){
      if(localStorage.getItem('authenticated') != 'true'){
        localStorage.setItem('authenticated', false)
        this.$router.push({ name: 'grant_access', params: { catalogCode: catalogCode } })
      }
      return
    }

  },
  metaInfo() {
    return {
      title: this.title
    }
  },
  methods:{
  }
}
</script>