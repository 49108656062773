<template>
    <div class="cart cart-sidebar-right" v-if="step == 1">
        <v-container v-if="!isMobile()" style="padding-bottom: 300px">
            <v-row >
                <v-col cols="1" class="top-title">
                    Imagem
                </v-col>
                <v-col cols="2" class="top-title">
                    Produto
                </v-col>
                <v-col cols="1" class="top-title">
                    Cor
                </v-col>
                <v-col cols="1" class="top-title">
                    Tam.
                </v-col>
                <v-col cols="1"  class="top-title" style="padding-left: 80px;">
                    Qtde.
                </v-col>
                <v-col cols="3"  class="top-title" style="padding-left: 80px;">
                    Unitário
                </v-col>
                <v-col cols="1"  class="top-title" style="padding-right: 80px;">
                    Total
                </v-col>
            </v-row>
            <v-row v-for="(p, index) in products" :key="index">
                <v-col cols="12" lg="1">
                    <v-img :src="p.imagem.nome" width="100%"></v-img>
                </v-col>
                <v-col cols="12" lg="2" class="product-title">
                    <span class="product-name">{{ p.nome }}</span><br>
                    <span class="product-ref">Ref: {{ p.referencia }}</span>
                </v-col>
                <v-col lg="1" class="product-color">
                    <v-row>
                        <v-col cols="4">
                            <div class="estampa" v-if="p.corData.estampaImagem != null">
                                <img :src="generateImageUrl(p.corData.estampaImagem)" alt="">
                            </div>
                            <div class="cor" :style="`background-color: ${p.corData.hex}`" v-if="p.corData.estampaImagem == null"></div>
                        </v-col>
                        <v-col cols="1" style="text-align: left;margin-top: 5px;">
                            {{ p.corData.nomeEstampa != null ? p.corData.nomeEstampa : p.corData.nome }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="1" style="padding-top: 43px;text-align: center;">
                    <strong>{{ p.tamanho }}</strong>
                </v-col>
                <v-col style="padding-top: 40px;" lg="6">
                    <v-row>
                        <v-col cols="1">
                            <v-btn icon @click="decrementProduct(p)" color="#ff4e54" :disabled="p.estoquePedido == 1">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1">
                            <div class="info-stock">
                                Estoque: {{ p.estoque }}
                            </div>
                            <input class="input-qnt" readonly v-model="p.estoquePedido">
                        </v-col>
                        <v-col cols="1">
                            <v-btn icon @click="incrementProduct(p)" color="#ff4e54" :disabled="p.estoquePedido >= p.estoque">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <strong>{{ p.quantidade }}</strong>
                        </v-col>
                        <v-col cols="3">
                            <strong>R$ {{ p.valor }}</strong>
                        </v-col>
                        <v-col cols="3">
                            <strong>R$ {{ (p.valor * p.estoquePedido).toFixed(2) }}</strong>
                        </v-col>
                        
                    </v-row>
                </v-col>
                <v-col cols="1" style="padding-top: 32px;">
                    <v-btn icon @click="deleteProduct(p)" color="#ff4e54">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="isMobile()" style="padding-bottom: 300px">
            <v-row v-for="(p, index) in products" :key="index" class="card-item">
                <v-col cols="6" lg="1">
                    <v-img :src="p.imagem.nome" width="100%"></v-img>
                </v-col>
                <v-col cols="6" lg="2" class="product-title-mobile">
                    <span class="product-name-mobile">{{ p.nome }}</span><br><br>
                    <span class="product-ref-mobile">Ref: {{ p.referencia }}</span><br><br>
                    <v-row>
                        <v-col cols="2">
                            <div class="estampa-mobile" v-if="p.corData.estampaImagem != null">
                                <img :src="generateImageUrl(p.corData.estampaImagem)" alt="">
                            </div>
                            <div class="cor-mobile" :style="`background-color: ${p.corData.hex}`" v-if="p.corData.estampaImagem == null"></div>
                        </v-col>
                        <v-col style="text-align: left;margin-top: 7px;font-size: 13px">
                            {{ p.corData.nomeEstampa != null ? p.corData.nomeEstampa : p.corData.nome }} &nbsp;<small>Tam:</small> {{ p.tamanho }}
                        </v-col>
                    </v-row>
                    <br>
                    <span class="product-cod-mobile">Cod.: {{ ('000' + p.corWear).substr(-3) }}</span><br><br>
                    <span class="product-ref-mobile">Un.: {{ p.valor }}</span>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2" lg="1">
                            <v-btn icon @click="decrementProduct(p)" color="#ff4e54" :disabled="p.estoquePedido == 1">
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="2" lg="1">
                            <div class="info-stock">
                                Estoque: {{ p.estoque }}
                            </div>
                            <input class="input-qnt"  readonly v-model="p.estoquePedido">
                        </v-col>
                        <v-col cols="2" lg="1">
                            <v-btn icon @click="incrementProduct(p)" color="#ff4e54" :disabled="p.estoquePedido >= p.estoque">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="2" lg="6" style="padding-top: 20px">
                            <strong>{{ parseFloat((p.valor * p.estoquePedido).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}</strong>
                        </v-col>
                        <v-col cols="4" style="text-align: right;">
                            <v-btn icon @click="deleteProduct(p)" color="#ff4e54">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                        
                    </v-row>
                </v-col>
                
            </v-row>
        </v-container>
        <v-container class="footer-total-fixed">
            <v-row>
                <v-col>

                </v-col>
                <v-col cols="12" lg="4" style="text-align: right;">
                    <strong>Total: <span>R$ {{ products.reduce((acc, p) => acc + (p.valor * p.estoquePedido), 0).toFixed(2) }}</span></strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                </v-col>
                <v-col>
                </v-col>
                <v-col cols="12" lg="2">
                    <button class="btn-close-order-text" @click="clearCart">Limpar Carrinho</button>

                </v-col>
                <v-col cols="12" lg="2">
                    <button class="btn-close-order" @click="initiateOrder">Fechar pedido</button>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <div class="cart cart-sidebar-right" v-else>
        <v-container>
            <v-row>
                <v-col>
                    <h1>Finalização do pedido</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-stepper v-model="e1">
                    <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Dados do cliente
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Confere dados
                    </v-stepper-step>

                    <v-divider></v-divider>
                    </v-stepper-header>

                    <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                label="Nome Completo"
                                required
                                outlined
                                v-model="customerData.nome"
                                ></v-text-field>
                            </v-col>
                            
                            <v-col cols="12" md="6">
                                <v-text-field
                                label="Whatsapp"
                                required
                                outlined
                                @keyup="formatPhone"
                                v-model="customerData.whatsapp"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                label="CPF/CNPJ"
                                required
                                outlined
                                v-model="customerData.cpfcnpj"
                                @keyup="formatCpfCnpj"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                label="Vendedor"
                                required
                                outlined
                                v-model="customerData.vendedor"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-select
                                :items="paymentMethods"
                                label="Método de pagamento"
                                v-model="customerData.metodo_pagamento"
                                outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                        <button class="btn-next" @click="validateStep1">
                        Avançar
                        </button>
                        
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                label="Nome Completo"
                                required
                                outlined
                                readonly
                                v-model="customerData.nome"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                label="Whatsapp"
                                required
                                outlined
                                readonly
                                @keyup="formatPhone"
                                v-model="customerData.whatsapp"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                label="CPF/CNPJ"
                                required
                                outlined
                                readonly
                                v-model="customerData.cpfcnpj"
                                @keyup="formatCpfCnpj"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field
                                label="Vendedor"
                                required
                                outlined
                                readonly
                                v-model="customerData.vendedor"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-select
                                :items="paymentMethods"
                                label="Método de pagamento"
                                outlined
                                readonly
                                v-model="customerData.metodo_pagamento"
                                ></v-select>
                            </v-col>
                        </v-row>

                        <button class="btn-next" @click="closeOrder">
                        Fechar Pedido
                        </button>
                    </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                </v-col>
            </v-row>
        </v-container>
    </div>

    
</template>

<script>
import generateImageUrl from '@/utils/generateImageUrl';
import createHash from 'hash-generator'


import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'CartComponent',
    props: {
        products: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            defaultImage: 'https://charmosatravessia.smartpdvstore.com/sem-imagem.jpg',
            step: 1,
            e1: 1,
            paymentMethods: ['PIX', 'CARTÃO CRÉDITO', 'CARTÃO DÉBITO', 'CHEQUE', 'BOLETO', 'DINHEIRO', "A COMBINAR"],
            customerData:{
                nome: '',
                whatsapp: '',
                cpfcnpj: '',
                vendedor: '',
                
            }
        }
    },
    methods: {
        incrementProduct(product){
            product.estoquePedido++
            this.$emit('incrementProduct', product)

        },
        decrementProduct(product){
            product.estoquePedido--
            this.$emit('decrementProduct', product)
        },
        deleteProduct(product){
            this.$emit('deleteProduct', product)
        },
        generateImageUrl(image) {
            return generateImageUrl(image, this.$store.state.userData)
        },
        isMobile() {
            const userAgent = navigator.userAgent.toLowerCase();
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        },
        initiateOrder(){
            this.step = 2
        },
        formatCpfCnpj(){
            let value = this.customerData.cpfcnpj
            value = value.replace(/\D/g, "")
            if(value.length > 11){
                value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            }else{
                value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            }
            this.customerData.cpfcnpj = value

        },
        formatPhone(){
            let value = this.customerData.whatsapp
            value = value.replace(/\D/g, "")
            value = value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
            this.customerData.whatsapp = value
        },
        validateStep1(){
            if(this.customerData.nome == '' || this.customerData.whatsapp == ''){
                alert('Preencha todos os campos')
            }else{
                this.e1 = 2
            }
        },

        clearCart(){
            this.$emit('clearCart')
        },

        async closeOrder(){
            this.e1 = 3
            try {
                let dataInsert = {
                    user_id: this.$store.state.userData.id,
                    cliente: this.customerData,
                    produtos: this.products,
                    hash: createHash(10),
                    total: parseFloat(this.products.reduce((acc, p) => acc + (p.valor * p.estoquePedido), 0).toFixed(2))
                }
                let response = await api.post('/orders', dataInsert)
                if(response.data.hash){
                    localStorage.setItem('cart', JSON.stringify([]))
                    this.$router.push(`/${this.$route.params.catalogCode}/order/${response.data.hash}`)
                }else{
                    alert('Erro ao finalizar pedido')
                }
            } catch (error) {
                console.log(error)
            }


        }
    }
}
</script>

<style scoped>

.btn-close-order{
    display: block;
    margin-left: auto;
    width: 80%;
    padding: 10px;
    color: #ff4e54;
    border: 2px solid #ff4e54;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.btn-close-order-text{
    display: block;
    margin-left: auto;
    width: 80%;
    padding: 10px;
    color: #ff4e54;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
}

.btn-next{
    width: 20%;
    padding: 10px;
    color: #ff4e54;
    border: 2px solid #ff4e54;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .btn-next{
        width: 100%;
    }
}

.footer-total-fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

strong {
    font-weight: bold;
}

strong span {
   font-size: 1.8em;
}










/* DESKTOP */
.cart-sidebar-right {
    position: fixed;
    overflow: auto;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 50px;
}

.product-title{
    padding-top: 40px;
    text-align: center;
}

.product-name {
    font-size: 1.3em;
    font-weight: 500;
    color: #696969
}

.cor {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #00000049;
    display: block;
    margin: 0 auto;
}

.product-color{
    padding-top: 40px;
    text-align: center;
    font-size: 1.1em;
}

.product-color{
    font-size: 1.0em;
}

.input-qnt{
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #00000049;
    text-align: center;
    margin-right: 5px;
}

/* REMOVE INPUT FOCUS BORDER */
.input-qnt:focus{
    outline: none;
}


.info-stock{
    position: absolute;
    background-color: #fff;
    margin-top: -27px;
    margin-left: -7px;
    box-shadow: 1px 2px 3px #69696975;
    border-radius: 3px;
    padding: 3px;
    font-size: .75rem;
}


.estampa{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}

.top-title{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    padding-top: 10px;
    color: #868e96
}





















/* MOBILE */

.product-title-mobile{
    text-align: left;
}

.product-name-mobile {
    text-align: left;
    font-size: 1.2em;
    font-weight: 300;
    color: #696969
}

.product-ref-mobile{
    color: #242424

}

.product-cod-mobile{
    color: #696969
}
.cor-mobile {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #00000049;
    display: block;
    margin: 0 auto;
    margin-top: 4px;
}

.product-color{
    padding-top: 40px;
    text-align: center;
    font-size: 1.1em;
}

.product-color{
    font-size: 1.0em;
}

.input-qnt{
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #00000049;
    text-align: center;
    margin-right: 5px;
}

/* REMOVE INPUT FOCUS BORDER */
.input-qnt:focus{
    outline: none;
}



@media (max-width: 768px) {
    .info-stock{
        position: absolute;
        background-color: #fff;
        margin-top: -23px;
        margin-left: -10px;
        box-shadow: 1px 2px 3px #69696975;
        border-radius: 3px;
        padding: 3px;
        font-size: .75rem;
    }
}



.estampa{
    margin-top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}

.top-title{
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    padding-top: 10px;
    color: #868e96
}

.card-item{
    border-bottom: 1px solid #00000049;
    padding: 10px;
}
</style>